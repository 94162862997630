import React, { useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import Grid from '@mui/material/Grid'
import Radio from '../common/Radio'
import Checkbox from '../common/Checkbox'
import RadioGroup from '@mui/material/RadioGroup'
// import Icon from '@mui/material/Icon'
import AddBoxIcon from '@mui/icons-material/AddBox';
import InputAdornment from '@mui/material/InputAdornment'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import TextField from './../common/TextField'
import MenuItem from '../common/MenuItem'
import Tooltip from '../common/Tooltip'
import { ReactComponent as AddNewIcon } from '../../assets/add-new.svg'
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Popover, Typography } from '@mui/material'
import TimePicker from 'react-time-picker'
// import { makeStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import { connect } from 'react-redux'
import DateRangePicker from './../common/DateRangePicker'
import Autocomplete from './../common/Autocomplete'
import Button from './../common/Button'
import DeviceSelector from './../Devices/DeviceSelector'
import GroupSelector from './../Devices/GroupSelector'
import DriverSelector from './../Devices/DriverSelector'
import moment from 'moment'
import NotificationSelector from '../../Components/Devices/NotificationSelector'
import NotificationTypeSelector from '../../Components/Devices/NotificationTypeSelector'
// import Slider from '@mui/material/Slider'
// import { styled } from '@mui/material/styles';
import Stepper from '../common/Stepper'
import GeofenceSelector from '../../Components/Devices/GeofenceSelector'
import { RemoteItems } from '../../Hooks/useRemoteItems'
import ItemGroupSelector from '../Devices/ItemGroupSelector'
import VehicleSelector from '../Devices/VehicleSelector'
import Loader from '../../Layout/Loader'
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// const PrettoSlider = styled(Slider)({
//   color: '#0A83A1',
//   height: 8,
//   '& .MuiSlider-track': {
//     border: 'none',
//   },
//   '& .MuiSlider-thumb': {
//     height: 22,
//     width: 22,
//     backgroundColor: '#fff',
//     border: '2px solid currentColor',
//     '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
//       boxShadow: 'inherit',
//     },
//     '&::before': {
//       display: 'none',
//     },
//   },
//   '& .MuiSlider-valueLabel': {
//     // lineHeight: 1.2,
//     fontSize: 10,
//     background: 'unset',
//     padding: 0,
//     width: 30,
//     height: 30,
//     marginTop:10,
//     borderRadius: '50% 50% 50% 0',
//     backgroundColor: '#0A83A1',
//     transformOrigin: 'bottom left',
//     transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
//     '&::before': { display: 'none' },
//     '&.MuiSlider-valueLabelOpen': {
//       transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
//     },
//     '& > *': {
//       transform: 'rotate(45deg)',
//     },
//   },
// });

const mapStateToProps = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themeColors
  }
}

// const styles = theme => ({})


// class addJobs extends Component {
const addJobs = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPopover, setCurrentPopover] = useState(null);
  const handleIconClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentPopover(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentPopover(null);
  };

  const open = Boolean(anchorEl);

  const descriptions = {
    includeTriggerDay: `Upon selection, the schedule will include data from the current day of execution. 
    For example, if the job is for the last two days, it will consider either 'today and yesterday' or 'yesterday and the day before.'
    Alternatively, you can simply say to include current day data in it or not`,
    includeExtendedHours: `This will include data from extended hours. For example, if the user selects data from 10 PM to 4 AM, it will decide whether to add data from 12:00 AM to 4:00 AM for the current day and from 10:00 PM to 12:00 AM for the previous day.`,
    includePartiallyInRange: `This option allows trips to pass the filter if either the start or end time falls within the selected range. For example, with a range of 8 AM to 6 PM, any trip that starts or ends within this range will be included. 
    If this option is not selected, only trips where both the start and end times fall entirely within the selected range (8 AM to 6 PM) will be included.`,
    withV1Merge: `This feature allows you to add real-time data to historical reports, such as data for today or the last 5 hours. While historical reports are typically prepared overnight, selecting this option will include data up to the time the report is generated. 
However, please note that this may cause the report generation to be slower, taking up to 10 minutes or more in some cases.`,
  };

  const selectRange = type => {
    const rangesFuture = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      'Next 7 Days': [
        moment().endOf('day'),
        moment()
          .add(7, 'days')
          .endOf('day')
      ],
      'Next 30 Days': [
        moment().endOf('day'),
        moment()
          .add(30, 'days')
          .endOf('day')
      ],
      'This Month': [
        moment()
          .startOf('month')
          .startOf('day'),
        moment()
          .endOf('month')
          .endOf('day')
      ],
      'Next Month': [
        moment()
          .add(1, 'month')
          .startOf('month')
          .startOf('day'),
        moment()
          .add(1, 'month')
          .endOf('month')
          .endOf('day')
      ],
      'Never End': [null, null]
    }
    if (rangesFuture && rangesFuture[type]) {
      props.showJobDates(rangesFuture[type][0], rangesFuture[type][1])
    }
    props.handleSelectedJobDate(type)
  }

  // const valuetext = value => {
  //   return moment('2016-03-13')
  //     .startOf('day')
  //     .add(value, 'minutes')
  //     .format('HH:mm')
  // }

  const selectRangePicker = (e, date) => {
    props.showJobDates(date.startDate, date.endDate)
  }

  const getStepContent = (id) => {
    let timezone = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)
    switch (id) {
      case 1:
        return (<Grid container className='form-group'>
          <Grid item xs={12}>
            <TextField
              id='description'
              label={props.translate('sharedName')}
              required
              placeholder=''
              value={props.form.description}
              onChange={props.handleChange('description')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ marginTop: 15, marginBottom: 10 }}>
              {props.translate('reportInterval')}
            </h4>
          </Grid>
          <Grid item xs={12} md={10} lg={11}>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='selectedDate'
                name='selectedDate'
                value={props.selectedDate + ''}
                onChange={e =>
                  props.selectedReportDateType(
                    'selectedDate',
                    e
                  )
                }
                row
              >
                {CustomDates.map(option => (
                  <FormControlLabel
                    key={option.id}
                    classes={{
                      label: 'form-contol-label'
                    }}
                    value={option.id + ''}
                    control={<Radio size='small' sx={{
                      color: props.themecolors.textColor,
                      '&.Mui-checked': {
                        color: props.themecolors.textColor,
                      },
                    }} />}
                    label={props.translate(option.label)}
                    labelPlacement='end'
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {props.selectedDate === 'custom' && (
              <TextField
                label={props.translate('reportDuration')}
                value={props.reportDuration}
                onChange={e =>
                  props.onDayChange2(
                    'reportDuration',
                    e.target.value
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='start'
                      disableTypography
                    >
                      {props.translate('days')}
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          <Accordion sx={{
            backgroundColor: props.themecolors?.backgroundColor,
            border: 'none',
            boxShadow: 'none',
            '&.MuiAccordion-root': {
              border: 'none',
            },
            '&.MuiAccordion-root:before': {
              height: '0px',
              content: 'none',
            },
            '& .MuiAccordionSummary-root': {
              border: 'none',
            },
            '& .MuiAccordionDetails-root': {
              border: 'none',
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: props.themecolors?.textColor }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                backgroundColor: props.themecolors?.backgroundColor,
                color:props.themecolors?.textColor,
                '&:hover': {
                  backgroundColor: props.themecolors?.backgroundColor,
                },
                height: '20px',
                padding: "0 5px",
                minHeight: '20px',
                transition: 'height 0.3s ease',
                '&.Mui-expanded': {
                  minHeight: '20px',
                  padding: "0 5px",
                },
              }}
            >
              <Typography component="span"  sx={{ fontWeight: 700 }}>Advance Settings</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}>
              <Grid item xs={12}>
                <Checkbox
                  checked={props.form.includeTriggerDay}
                  onChange={props.handleChange('includeTriggerDay')}
                  name="includeTriggerDay"
                  label={props.translate('Include Current Day')}
                />
                <IconButton
                  aria-describedby="includeTriggerDay"
                  onClick={(e) => handleIconClick(e, 'includeTriggerDay')}
                >
                   <InfoIcon style={{ color:props.themecolors?.textColor}} />
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <Checkbox
                  checked={props.form.includeExtendedHours}
                  onChange={props.handleChange('includeExtendedHours')}
                  name="includeExtendedHours"
                  label={props.translate('Include Extended Hours')}
                />
                <IconButton
                  aria-describedby="includeExtendedHours"
                  onClick={(e) => handleIconClick(e, 'includeExtendedHours')}
                >
        <InfoIcon style={{ color:props.themecolors?.textColor}} />
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <Checkbox
                  checked={props.form.includePartiallyInRange}
                  onChange={props.handleChange('includePartiallyInRange')}
                  name="includePartiallyInRange"
                  label={props.translate('Include Partially In Range')}
                />
                <IconButton
                  aria-describedby="includePartiallyInRange"
                  onClick={(e) => handleIconClick(e, 'includePartiallyInRange')}
                >
                  <InfoIcon style={{ color:props.themecolors?.textColor}} />
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <Checkbox
                  checked={props.form.attributes.withV1Merge}
                  onChange={props.handleChange('withV1Merge')}
                  name="withV1Merge"
                  label={props.translate('WithV1Merge')}
                />
                <IconButton
                  aria-describedby="withV1Merge"
                  onClick={(e) => handleIconClick(e, 'withV1Merge')}
                >
               <InfoIcon style={{ color:props.themecolors?.textColor}} />
                </IconButton>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              width: '100%',
              maxWidth: '1000px',
              overflow: 'hidden',
            }}
          >
            <Typography sx={{ p: 1, fontSize: '10px', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
              {descriptions[currentPopover]}
            </Typography>
          </Popover>

          <Grid item xs={12}>
            <h4 style={{ marginTop: 15, marginBottom: 10 }}>
              {props.translate('reportForDays')}
            </h4>
            <div
              style={{
                border: `1px solid ${props.themecolors.textColor}15`,
                display: 'inline-flex'
              }}
            >
              {day.map(option => (
                <div
                  key={option.id}
                  style={{
                    display: 'inline-flex',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    marginRight: option.id === '7' ? 0 : 1,

                    backgroundColor: props.form.attributes.controlDays?.includes(
                      parseInt(option.id)
                    )
                      ? props.themecolors && props.themecolors.themeLightColor
                      : props.themecolors.backgroundColor,
                    color: props.form.attributes.controlDays?.includes(
                      parseInt(option.id)
                    ) ? props.themecolors && props.themecolors.themeLightInverse : props.themecolors.textColor
                  }}
                >
                  <label
                    style={{
                      padding: 5,
                      minWidth: 30,
                      cursor: 'pointer'
                    }}
                  >
                    <input
                      type='checkbox'
                      key={option.id}
                      checked={props.form.attributes.controlDays?.includes(
                        parseInt(option.id)
                      )}
                      onChange={e =>
                        props.onDayChange3(e.target.checked, option)
                      }
                      style={{ display: 'none' }}
                    />
                    {props.translate(option.name)}
                  </label>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ margin: "10px 0px" }}>
              {props.translate('reportBetweenTime')}
            </h4>
            {/* <TextField
            id='description'
            label={props.translate('sharedName')}
            required
            placeholder=''
            value={props.form.description}
            onChange={props.handleChange('description')}
            variant='outlined'
            margin='dense'
            fullWidth
            type="time"
          /> */}
            <div style={{ display: "flex" }}>
              <div>
                <TextField
                  id='controlTimeStart'
                  label={props.translate('Start Time')}
                  required
                  placeholder=''
                  value={props.form.attributes.controlTimeStart || "00:00"}
                  // onChange={props.handleChange('description')}
                  onChange={event => props.handleTimeControl(event, 'start')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  type="time"
                />
                {/* <TimePicker
              clearIcon={false}
              disableClockull
              // }
              value={
                props.form.attributes.controlTimeStart ?
                props.form.attributes.controlTimeStart
                  // ? moment(props.form.attributes.controlTimeStart).format(
                  //     'HH:mm'
                  //   )
                  : null
              }
              minutePlaceholder='mm'
              hourPlaceholder='hh'
              onChange={event => props.handleTimeControl(event, 'start')}
            /> */}
              </div>
              <div style={{ margin: "0px 10px" }}>
                <TextField
                  id='controlTimeEnd'
                  label={props.translate('End Time')}
                  required
                  placeholder=''
                  value={props.form.attributes.controlTimeEnd || "23:59"}
                  // onChange={props.handleChange('description')}
                  onChange={event => props.handleTimeControl(event, 'end')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  type="time"
                />
                {/* <TimePicker
              clearIcon={false}
              disableClock
              value={
                props.form.attributes.controlTimeEnd ?
                props.form.attributes.controlTimeEnd  
                  // ? moment(props.form.attributes.controlTimeEnd).format(
                  //     'HH:mm'
                  //   )
                  : "00:00"
              }
              minutePlaceholder='mm'
              hourPlaceholder='hh'
              onChange={event => props.handleTimeControl(event, 'end')}
            /> */}
              </div>
              {/* <Slider
              value={props.controlTime}
              onChange={props.handleTimeControl}
              valueLabelDisplay='on'
              aria-labelledby='range-slider'
              valueLabelFormat={valuetext}
              max={1439}
              classes={{
                root: 'theme-range-slider',
                thumb: 'theme-range-slider-thumb',
                track: 'theme-range-slider-track',
                rail: 'theme-range-slider-rail',
                valueLabel: 'theme-range-slider-label'
              }}
              step={5}
            /> */}
            </div>
          </Grid>
        </Grid>)
      case 2:
        return (<Grid container className='form-group'>
          <Grid item xs={12}>
            <TextField
              select
              label={props.translate('selectTemplate')}
              value={props.reportType || ''}
              variant='outlined'
              onChange={props.reportTypeChange}
              margin='dense'
              fullWidth
            >
              {props.templates.map(option => (
                (option.rpTmpl.active ? <MenuItem key={option.rpTmpl.id} value={option.rpTmpl.id}>
                  {option.rpTmpl.name}
                </MenuItem> : null)
              ))}
            </TextField>
          </Grid>
          {props.allowFields.includes('driver') ||
            props.allowFields.includes('device') ||
            props.allowFields.includes('vehicle') ||
            props.allowFields.includes('notification') ? (
            <Grid item xs={12} style={{ padding: '8px 0' }}>
              <div className='report-sidebar-switch'>
                {props.allowFields.includes('device') ? (
                  <Button
                    onClick={() =>
                      props.reportTypeSelection('device')
                    }
                    className={
                      (props.reportUnits === 'device'
                        ? 'active'
                        : '') + ' button-inactive'
                    }
                  >
                    {props.translate('trackers')}
                  </Button>
                ) : null}
                {/* {props.allowFields.includes('group') ? (
              <Button
                onClick={()=> props.reportTypeSelection('group')}
                className={
                  (props.reportUnits === 'group'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {props.translate('groups')}
              </Button>
            ) : null} */}
                {props.allowFields.includes('vehicle') ? (
                  <Button
                    onClick={() =>
                      props.reportTypeSelection('vehicle')
                    }
                    className={
                      (props.reportUnits === 'vehicle'
                        ? 'active'
                        : '') + ' button-inactive'
                    }
                  >
                    {props.translate('sharedVehicle')}
                  </Button>
                ) : null}
                {props.allowFields.includes('driver') ? (
                  <Button
                    onClick={() =>
                      props.reportTypeSelection('driver')
                    }
                    className={
                      (props.reportUnits === 'driver'
                        ? 'active'
                        : '') + ' button-inactive'
                    }
                  >
                    {props.translate('sharedDrivers')}
                  </Button>
                ) : null}
              </div>
              <div className='report-sidebar-switch'>
                <a className={"btn-link " + (props.groupView === 1 ? 'active' : '')} href={null} onClick={() => props.switchGroupView(1)}>
                  {props.reportUnits === 'device' ? props.translate('trackers') : props.reportUnits === 'driver' ? props.translate('driver') : props.reportUnits === 'vehicle' ? props.translate('sharedVehicle') : ''}
                </a>
                <a className={"btn-link " + (props.groupView === 2 ? 'active' : '')} href={null} onClick={() => props.switchGroupView(2)}>
                  {props.reportUnits === 'device' ? props.translate('groups') : props.reportUnits === 'driver' ? props.translate('driverGroups') : props.reportUnits === 'vehicle' ? props.translate('vehicleGroups') : ''}
                </a>
              </div>
            </Grid>
          ) : null}
          {props.reportUnits === 'device' &&
            props.allowFields.includes('device') ? (
            <div>
              {props.groupView === 2 ?
                // <GroupSelector
                //   value={props.groupIds}
                //   onChange={props.selectedGroups}
                //   rows={8}
                // />
                <RemoteItems type="Device">
                  {({ data, isLoaded }) => isLoaded ? <ItemGroupSelector
                    groupList={data}
                    value={props.groupIds}
                    onChange={props.selectedGroups}
                    rows={8}
                  /> : <Loader defaultStyle />}
                </RemoteItems>
                :
                <DeviceSelector
                  value={props.deviceIds}
                  onChange={props.selectedDevices}
                  rows={8}
                />}
            </div>
          ) : props.reportUnits === 'group' &&
            props.allowFields.includes('group') ? (
            <GroupSelector
              value={props.groupIds}
              onChange={props.selectedGroups}
              rows={8}
            />
          ) : props.allowFields.includes('driver') ? (
            <div>
              {props.groupView === 2 ?
                <RemoteItems type="Driver">
                  {({ data, isLoaded }) => isLoaded ? <ItemGroupSelector
                    groupList={data}
                    value={props.groupIds}
                    onChange={props.selectedGroups}
                    rows={8}
                  /> : <Loader defaultStyle />}
                </RemoteItems>
                : <DriverSelector
                  disableModal={true}
                  value={props.driverIds}
                  onChange={props.selectedDrivers}
                  rows={8}
                />}
            </div>
          ) : props.allowFields.includes('vehicle') ? (
            <div>
              {props.groupView === 2 ?
                <RemoteItems type="Vehicle">
                  {({ data, isLoaded }) => isLoaded ? <ItemGroupSelector
                    groupList={data}
                    value={props.groupIds}
                    onChange={props.selectedGroups}
                    rows={8}
                  /> : <Loader defaultStyle />}
                </RemoteItems>
                : <VehicleSelector
                  value={props.vehicleIds}
                  onChange={props.selectedVehicles}
                  rows={8}
                />}
            </div>

          ) : null}
        </Grid>)
      case 3:
        return (<Grid container className='form-group'>

          {props.allowFields.includes('eventType') ||
            props.allowFields.includes('notification') ? (
            <Grid item xs={12}>
              <div className='report-sidebar-switch'>
                {props.allowFields.includes('notification') ? (
                  <Button
                    onClick={() =>
                      props.handleChangeType('notification')
                    }
                    className={
                      props.eventType === 'notification'
                        ? ''
                        : 'button-inactive'
                    }
                  >
                    {props.translate("event")}
                  </Button>
                ) : null}
                {props.allowFields.includes('eventType') ? (
                  <Button
                    onClick={() =>
                      props.handleChangeType('eventType')
                    }
                    className={
                      props.eventType === 'eventType'
                        ? ''
                        : 'button-inactive'
                    }
                  >
                    {props.translate("type")}
                  </Button>
                ) : null}
              </div>
              {props.eventType === 'eventType' &&
                props.allowFields.includes('eventType') ? (
                <NotificationTypeSelector
                  value={props.types}
                  onChange={props.selectedNotifications}
                />
              ) : null}
              {props.eventType === 'notification' &&
                props.allowFields.includes('notification') ? (
                <NotificationSelector
                  value={props.notificationIds}
                  onChange={props.selectedEvents}
                />
              ) : null}
            </Grid>
          ) : null}
        </Grid>)

      case 4:
        return (<Grid container className='form-group' spacing={2}>
          {props.allowFields.includes('geofence') ? <>
            <Grid item xs={12}><GeofenceSelector
              value={props.geofenceIds}
              onChange={props.selectedGeofences}
              rows={8}
            />
            </Grid>
            <Grid item xs={12}>
              <TextField type='number' onChange={props.handleChange('geoTimeThreshold')} label={props.translate("geoTimeThreshold") + " (" + props.translate("sharedMinutes") + ")"} value={props.form.attributes.geoTimeThreshold} fullWidth />
            </Grid>
          </> : null}
        </Grid>)

      case 5:
        return (<Grid container className='form-group'>
          {props.allowFields.includes('sensor') || props.allowFields.includes('digitalSensor') ?
            <SensorSelector digital={props.allowFields.includes('digitalSensor')} allSensors={props.allSensors} value={props.sensorValues} handleChange={props.selectedSensors} translate={props.translate} />
            : null}
        </Grid>)

      case 6:
        return (<div className='form-group'>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='selectedJobDate'
              name='selectedJobDate'
              value={props.selectedJobDate}
              onChange={e =>
                selectRange(
                  e.target.value
                )
              }
              row
            >
              {['reportToday', 'reportNext7Days', 'reportNext30Days', 'reportThisMonth', 'reportNextMonth', 'endNever', 'custom'].map(option => (
                <FormControlLabel
                  key={option}
                  classes={{
                    label: 'form-contol-label'
                  }}
                  value={option}
                  control={<Radio size='small' sx={{
                    color: props.themecolors.textColor,
                    '&.Mui-checked': {
                      color: props.themecolors.textColor,
                    },
                  }} />}
                  label={props.translate(option)}
                  labelPlacement='end'
                />
              ))}


            </RadioGroup>
          </FormControl>
          {props.selectedJobDate === 'custom' ? (
            <div
              style={{
                width: 225
              }}
            >
              <DateRangePicker
                fill
                fullWidth
                ranges={[]}
                minDate={moment()}
                selectedDate={`${props.form.start
                  ? moment(props.form.start).format(
                    'YYYY-MM-DD HH:mm'
                  )
                  : ''
                  }-${props.form.end
                    ? moment(props.form.end).format(
                      'YYYY-MM-DD HH:mm'
                    )
                    : ''
                  }`}
                label={props.translate('schedule')}
                onEvent={selectRangePicker}
              />
            </div>
          ) : null}
          <h4 style={{ marginTop: 15, marginBottom: 10 }}>{props.translate('triggerTime')}</h4>
          <TimePicker
            clearIcon={false}
            disableClock
            value={
              props.form.triggerTime
                ? moment(props.form.triggerTime).format(
                  'HH:mm'
                )
                : null
            }
            minutePlaceholder='mm'
            hourPlaceholder='hh'
            onChange={props.handleChange('triggerTime')}
          />
          <h4 style={{ marginTop: 15, marginBottom: 10 }}>{props.translate('repeat')}</h4>
          <TextField
            select
            label={props.translate('repeat')}
            value={props.form.period || ''}
            variant='outlined'
            onChange={e => props.handleChangePeriod(e.target.value)}
            margin='dense'
            fullWidth
          >
            {repeat.map(option => (
              <MenuItem key={option.key} value={option.key} >
                {props.translate(option.value)}
              </MenuItem>
            ))}
          </TextField>

          {props.form &&
            props.form.period &&
            ['daily', 'weekly', 'monthly'].includes(
              props.form.period
            ) && (
              <Grid item xs={12}>
                {props.form.period === 'daily'
                  ? (<div
                    style={{
                      border: `1px solid ${props.themecolors.textColor}15`,
                      display: 'inline-flex'
                    }}
                  >
                    {day.map(option => (
                      <div
                        key={option.id}
                        style={{
                          display: 'inline-flex',
                          textTransform: 'uppercase',
                          fontWeight: 600,
                          marginRight: option.id === '7' ? 0 : 1,

                          backgroundColor: props.form.days.includes(
                            option.id
                          )
                            ? props.themecolors && props.themecolors.themeLightColor
                            : 'transparent',
                          color: props.themecolors.textColor
                        }}
                      >
                        <label
                          style={{
                            padding: 5,
                            minWidth: 30,
                            cursor: 'pointer'
                          }}
                        >
                          <input
                            type='checkbox'
                            key={option.id}
                            checked={props.form.days.includes(
                              parseInt(option.id)
                            )}
                            onChange={() =>
                              props.onDayChange(option)
                            }
                            style={{ display: 'none' }}
                          />
                          {props.translate(option.name)}
                        </label>
                      </div>
                    ))}
                  </div>) : null}
                {props.form.period === 'weekly'
                  ? (<div
                    style={{
                      border: `1px solid ${props.themecolors.textColor}15`,
                      display: 'inline-flex'
                    }}
                  >
                    {day.map(option => (
                      <div
                        key={option.id}
                        style={{
                          display: 'inline-flex',
                          textTransform: 'uppercase',
                          fontWeight: 600,
                          marginRight: option.id === '7' ? 0 : 1,

                          backgroundColor: parseInt(props.form.days) === parseInt(option.id)
                            ? props.themecolors && props.themecolors.themeLightColor
                            : 'transparent',
                          color: props.themecolors.textColor
                        }}
                      >
                        <label
                          style={{
                            padding: 5,
                            minWidth: 30,
                            cursor: 'pointer'
                          }}
                          onClick={() =>
                            props.onDayChange2('days', option.id)
                          }
                        >
                          {props.translate(option.name)}
                        </label>
                      </div>
                    ))}
                  </div>) : null}

                {props.form.period === 'monthly' ? (
                  <TextField
                    type="number"
                    fullWidth
                    label={props.translate('date')}
                    value={props.form.days}
                    onChange={e =>
                      props.onDayChange2(
                        'days',
                        e.target.value
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position='start'
                          disableTypography
                        >
                          {props.translate('day')}
                        </InputAdornment>
                      )
                    }}
                  />
                ) : null}
              </Grid>
            )}
          <h4 style={{ marginTop: 15, marginBottom: 10 }}>{props.translate('setEmail')}</h4>
          <RadioGroup
            aria-label='position'
            name='position'
            value={props.form.mailTo}
            onChange={e =>
              props.handleChangeForEmails('mailTo', e)
            }
            row
          >
            <FormControlLabel
              classes={{
                label: 'form-contol-label'
              }}
              value='user'
              control={<Radio size='small' sx={{
                color: props.themecolors?.textColor,
                '&.Mui-checked': {
                  color: props.themecolors?.textColor,
                },
              }} />}
              label={props.translate('userEmail')}
              labelPlacement='end'
            />
            <FormControlLabel
              classes={{
                label: 'form-contol-label'
              }}
              value='custom'
              control={<Radio size='small' sx={{
                color: props.themecolors?.textColor,
                '&.Mui-checked': {
                  color: props.themecolors?.textColor,
                },
              }} />}
              label={props.translate('customEmails')}
              labelPlacement='end'
            />
          </RadioGroup>
          {props.form.mailTo !== 'user' && (
            <Grid item xs={12}>
              {props.form.email &&
                props.form.email.map(item => {
                  return (
                    <div
                      style={{ display: 'flex' }}
                      key={item.id}
                    >
                      <TextField
                        id='email'
                        label={props.translate('userEmail')}
                        placeholder=''
                        value={item.text || ''}
                        onChange={e =>
                          props.handleChangeForEmails(
                            'email',
                            e,
                            item.id
                          )
                        }
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                      {item.id ===
                        props.form.email.length && (
                          <Tooltip
                            title={props.translate(
                              'addAnother'
                            )}
                          >
                            <IconButton
                              color='inherit'
                              aria-label='directions'
                              onClick={() =>
                                props.onAddAnotherMail('email')
                              }
                            >
                              <AddNewIcon
                                width={18}
                                height={18}
                                style={{ fill: 'currentColor' }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      {1 < props.form.email.length && (
                        <Tooltip
                          title={props.translate('delete')}
                        >
                          <IconButton
                            color='inherit'
                            aria-label='directions'
                            onClick={() =>
                              props.deleteMail(
                                'email',
                                item.id
                              )
                            }
                          >
                            <DeleteIcon
                              width={18}
                              height={18}
                              style={{ fill: 'currentColor' }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  )
                })}
            </Grid>
          )}
          <h4 style={{ marginTop: 15, marginBottom: 10 }}>{props.translate('attachmentType')}</h4>
          <RadioGroup
            aria-label='position'
            name='position'
            value={props.form.attributes.attachmentType}
            onChange={props.handleChange('attachmentType')}
            row
          >
            <FormControlLabel
              classes={{
                label: 'form-contol-label'
              }}
              value='excel'
              control={<Radio size='small' sx={{
                color: props.themecolors?.textColor,
                '&.Mui-checked': {
                  color: props.themecolors?.textColor,
                },
              }} />}
              label={props.translate('excel')}
              labelPlacement='end'
            />
            <FormControlLabel
              classes={{
                label: 'form-contol-label'
              }}
              value='pdf'
              control={<Radio size='small' sx={{
                color: props.themecolors?.textColor,
                '&.Mui-checked': {
                  color: props.themecolors?.textColor,
                },
              }} />}
              label={props.translate('pdf')}
              labelPlacement='end'
            />
          </RadioGroup>


          {props.editOption && (
            <>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                {props.translate('status')}
              </h4>
              <Grid item xs={12}>
                <Checkbox
                  checked={props.form.status}
                  onChange={props.handleChange('status')}
                  name='status'
                  label={props.translate('enable')}
                />
              </Grid>
            </>
          )}
        </div>)


      default:
        return null
    }
  }

  // render () {
  return (<div style={{ background: props.themecolors.backgroundColor, color: props.themecolors.textColor, borderRadius: 8, padding: 16 }}>
    <h4 className='with-border'>{props.translate('reportForm')}</h4>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Stepper translate={props.translate} activeStep={props.activeStep} reportType={props.reportType} getStepContent={getStepContent} steps={props.steps} handleNext={props.goToNextStep} handleBack={props.goToBackStep} themecolors={props.themecolors} />
      </Grid>
    </Grid>
  </div>)
}
// }

// const AddJobs =  (styles)
// export default connect(mapStateToProps)(AddJobs(withLocalize(addJobs)))

export default connect(mapStateToProps)(
  withLocalize(addJobs)
)


const repeat = [
  {
    key: 'once',
    value: 'once'
  },
  {
    key: 'daily',
    value: 'daily'
  },
  {
    key: 'weekly',
    value: 'weekly'
  },
  {
    key: 'monthly',
    value: 'monthly'
  }
]


const day = [
  {
    id: '1',
    name: 'su'
  },
  {
    id: '2',
    name: 'mo'
  },
  {
    id: '3',
    name: 'tu'
  },
  {
    id: '4',
    name: 'we'
  },
  {
    id: '5',
    name: 'th'
  },
  {
    id: '6',
    name: 'fr'
  },
  {
    id: '7',
    name: 'sa'
  }
]

const CustomDates = [
  {
    id: 0,
    label: 'reportToday'
  },
  {
    id: 1,
    label: 'reportYesterday'
  },
  {
    id: 7,
    label: 'reportLastWeek'
  },
  {
    id: 14,
    label: 'reportLastTwoWeek'
  },
  {
    id: 30,
    label: 'reportLastMonth'
  },
  {
    id: 60,
    label: 'reportLastTwoMonths'
  },
  {
    id: 90,
    label: 'reportLastThreeMonths'
  },
  {
    id: 'custom',
    label: 'custom'
  }
]


const SensorSelector = (props) => {
  const [scale, setScale] = useState(props.value || [])

  const addMore = () => {
    const newscale = [...scale]
    newscale.push({ input: "", index: "", value: "" })
    setScale(newscale)
  }

  const deleteRow = (index) => {
    let newscale = [...scale]
    newscale.splice(index, 1)
    setScale(newscale)

    props.handleChange(newscale)
  }

  const handleChange = (name, value, row) => {
    if (name === 'allSensors') {
      if (value.target.checked) {
        const reset = [{ input: '', index: '', value: 'allSensors' }];
        setScale(reset)
        props.handleChange(reset)
      }
      else {
        const reset = [{ input: '', index: '', value: '' }];
        setScale(reset)
        props.handleChange(reset)
      }
    } else {
      row[name] = value
      row.value = row.input + (row.index !== undefined ? row.index : "")

      const newscale = [...scale];
      setScale(newscale);

      props.handleChange(newscale)
    }

  }

  const suggestions = props.digital ? [
    'ignition',
    'panicButton',
    'externalPower',
    'enginStatus',
    'carAlarm',
    'doors',
    'customDiscrete'
  ] : [
    "driverUniqueId",
    "passengerUniqueId",
    "binUniqueId",
    "trailerUniqueId",
    "ignition",
    "panicButton",
    "externalPower",
    "enginStatus",
    "carAlarm",
    "doors",
    "fuel",
    "temperature",
    "rpm",
    "boardPower",
    "mileage",
    "speed",
    "counter",
    "averagFuelConsumption",
    "instantFuelConsumption",
    "flowMeter",
    "humidity",
    "custom"
  ];

  return <div style={{ marginBottom: 16, width: '100%' }}>
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 0, marginTop: -22 }}>
        <div className="pull-right">
          {!props.allSensors && <Tooltip title={props.translate('addMore')} classes={{
            popper: 'menu-popper',
            tooltip: 'menu-popper-tooltip'
          }}>
            <a
              href={null}
              onClick={addMore}
              style={{ color: 'inherit' }}
            >
              <AddBoxIcon />
            </a>
          </Tooltip>}
          <Checkbox checked={props.allSensors} onChange={e => handleChange('allSensors', e)} label={props.translate('selectAll')} />
        </div>

      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        {!props.allSensors && scale.map((row, index) => (
          <Grid
            key={index}
            container
            spacing={0}
            className='sensors-rows'
          >
            <Grid item xs={scale.length > 1 ? 11 : 12}>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Autocomplete
                    value={row.input || ''}
                    suggestions={suggestions}
                    handleChange={(e, v) => handleChange(e, v, row)}
                    fieldName='input'
                    label={props.translate('sensorType')}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    type='number'
                    value={row.index}
                    onChange={e => handleChange('index', e.target.value, row)}
                    variant='outlined'
                    margin='dense'
                    label={props.translate('index')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {scale.length > 1 ? (
              <Grid
                item
                xs={1}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex'
                }}
              >
                <DeleteIcon
                  onClick={() => deleteRow(index)}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </div>
}