import React, { useEffect, useRef, useState } from 'react'
import L from 'leaflet'
import {
  MapContainer,
  TileLayer,
  Circle,
  Polygon,
  Tooltip,
  Polyline,
  Marker,
  Popup,
  ZoomControl,
  FeatureGroup
} from 'react-leaflet'
import { useSelector } from 'react-redux'
import TextPath from 'react-leaflet-textpath'
import moment from 'moment'
import * as turf from '@turf/turf'
import './../common/Maps/DrawMap.scss'
import './../../assets/KML/index.js'
import ReactHtmlParser from 'react-html-parser'
import { getDateTimeFormat, setAttributeFormat } from '../../Helpers'
import { MapFilters } from './MapFilters'
import { EditControl } from 'react-leaflet-draw'
const settings = {
  rectangle: false,
  circle: false,
  circlemarker: false,
  marker: false,
  polyline: false,
  polygon: false
}
function colorToRgba(color, opacity) {
  // Function to convert HEX to RGBA
  function hexToRgba(hex, opacity) {
    hex = hex.replace(/^#/, '');

    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  const namedColors = {
    red: '255, 0, 0',
    blue: '0, 0, 255',
    green: '0, 128, 0',
    yellow: '255, 255, 0',
    black: '0, 0, 0',
    white: '255, 255, 255',
  };
  if (color.startsWith('#')) {
    // HEX color
    return hexToRgba(color, opacity);
  } else {
    // Named color
    const rgb = namedColors[color.toLowerCase()];
    if (rgb) {
      return `rgba(${rgb}, ${opacity})`;
    } else {
      return `rgba(0, 0, 0, ${opacity})`;
    }
  }
}
const RouteMap = (props) => {
  const [state, setState] = useState({
    lat: 0,
    lng: 0,
    zoom: 3,
    minZoom: 3,
    MarkerDOM: null,
    fitBounds: {},
    bounds: [],
    position: [0, 0],
    kmlLayer: null,
    whichControls: "",
    Measuring: '',
    polylineMarkers: [],
    MeasureEditMode: false,
  })
  const [newBounds, setnewBounds] = useState([])
  const [isCallBoundsFit, setIsCallBoundsFit] = useState(false)


  const mapLayer = useSelector((state) => state.mapLayer)
  const geoFence = useSelector((state) => state.geoFence)
  const logInUser = useSelector((state) => state.logInUsers)
  const ServerSetting = useSelector((state) => state.ServerSetting)
  const mapRef = useRef(null);
  const isInitialized = useRef(false);
  //measure is create STATE
  const [createData, setCreateData] = useState(null)
  const [isCreateData, setIsCreateData] = useState(false)
  // MEASURE IS STOP STATE
  const [stopData, setStopData] = useState(null)
  const [drawStop, setDrawStop] = useState(false)
  const [isDrawing, setIsDrawing] = useState(false)
  // FOR STORE LAYERS
  const [selectedLayers, setSelectedLayers] = useState([])

  let reactECref = useRef({});

  // clear prevstate
  const clearPrevState = () => {
    if (selectedLayers?.length > 0) {
      selectedLayers.forEach((layer) => {
        if (layer &&layer.options?.newShap === true) {
          layer.remove();
        }
      });
  
      // setSelectedLayers(selectedLayers.filter((layer) => layer.options?.newShap !== true));
      setSelectedLayers([]);
    }

    if (state.polylineMarkers?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        polylineMarkers: [],
      }));
    }

    setState((prevState) => ({
      ...prevState,
      Measuring: "",
      MeasureEditMode: false,
    }));
  };

  // for press esc
  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "Escape") {
      if(isDrawing){
        clearPrevState();
      }
      }
    };
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [selectedLayers,isDrawing]); 

  // START NEW
  const addMeasureShape = (type) => {
    clearPrevState();
    setState((prevState) => ({
      ...prevState,
      whichControls: "measure",
      Measuring: type,
    }))
    setIsDrawing(true)
    addShape(type);
  };

  // isStart
  const _onMeasureDrawStart = e => {
    setState((prevState) => ({
      ...prevState,
      Measuring: e.layerType
    }));
    setIsDrawing(true)

  }

  // isReady
  const _onMeasureControlReady = reactECrefar => {
    if (!isInitialized.current) {
      reactECref.current = reactECrefar;
      reactECref.current._container.classList.add('custom-edit')
      isInitialized.current = true;
    }
  }

  // isCreated
  const _onMeasureDrawCreated = (e) => {
    setCreateData(e);
    setIsCreateData(true);
    const newLayer = e.layer;
    setSelectedLayers((prevLayers) => [...prevLayers, newLayer]);
  };

  // isCreated then
  useEffect(() => {
    if (isCreateData) {

      if (state.whichControls === 'measure') {
        let latlng = []
        let dist = 0
        if (createData?.layers.getLayers().length) {
          createData?.layers.getLayers().map(layer => {
            latlng.push(layer._latlng)
            return null
          })
          for (let i = 0; i < latlng.length; i++) {
            if (latlng[i + 1]) {
              dist += latlng[i].distanceTo(latlng[i + 1])
            }
          }
          let polylineMarkers = state.polylineMarkers
          let currentLatlng = latlng[latlng.length - 1]
          polylineMarkers.push(
            <Marker
              ref={openPopup}
              key={new Date().getTime()}
              position={currentLatlng}
              iconAnchor={[21, 41]}
            >

              <Tooltip direction={'top'} permanent>
                {dist ? (dist / 1000).toFixed(2) : '0'} km
              </Tooltip>
            </Marker>
          )
          setState((prevState) => ({
            ...prevState,
            polylineMarkers: [...polylineMarkers]
          }))
        }
      }
      setIsCreateData(false)
    }

  }, [isCreateData, state.whichControls, createData])

// is Stop
  const _onMeasureDrawStop = (e) => {
    setStopData(e);
    setDrawStop(true);
    const layers = [];
    Object.keys(e.target._targets).forEach((layerId) => {
      if (layerId !== e.target._containerId) {
        const layer = e.target._targets[layerId];
        if (layer && layer._path) {
          layers.push(layer);
        }
      }
    });

    setSelectedLayers(layers);
  };
  // isDrawStop then
  useEffect(() => {
    if (drawStop) {
      if (state.whichControls === 'measure') {
        let currentShap = {}
        Object.keys(stopData?.target._targets).map(id => {
          if (parseInt(id) !== stopData.target._containerId) {
            currentShap = stopData.target._targets[id]
          }
          return null
        })
        if (Object.keys(currentShap).length && currentShap.options.newShap) {
          if (state.Measuring === 'polygon') {
            if (currentShap._latlngs) {
              let area = L.GeometryUtil.geodesicArea(currentShap._latlngs[0]) || 0
              currentShap._path.attributes.fill.value = colorToRgba("#045266", 0.3);
              let areaFormated = area ? area.toFixed(2) + ' m<sup>2</sup>' : 0
              currentShap
                .bindPopup(
                  '<div className="position-box"><strong>Area</strong><div className="position-box-body">Total area: ' +
                  areaFormated +
                  '</div></div>'
                )
                .openPopup()
            }
          } else if (state.Measuring === 'polyline') {
            let polylineMarkers = state.polylineMarkers
            setState((prevState) => ({
              ...prevState,
              polylineMarkers: [...polylineMarkers]
            }))

          }
          setState((prevState) => ({
            ...prevState,
            MeasureEditMode: false
          }))
        } else {
          setState((prevState) => ({
            ...prevState,
            MeasureEditMode: false
          }))

        }

        if (stopData && stopData.target && stopData.target._targets) {
          let layers = []
          Object.keys(stopData.target._targets).map((layer) => {
            if (layer + '' !== stopData.target._containerId + '') {
              let l = stopData.target._targets[layer]
              if (l && l._path) {
                layers.push(l)
              }
            }
            return null
          })
          if (layers.length === 0) {
            //console.log('zero');
          }
          setSelectedLayers(layers)
        }

      }
      setIsDrawing(false)

      setDrawStop(false)
    }

  }, [stopData, drawStop, state.whichControls, state.Measuring])


  const addShape = (type) => {
    if (reactECref.current&&mapRef.current) {

      if (type === 'circle') {
        reactECref.current?._toolbars?.draw?._toolbarContainer?.children[2]?.click()
      } else if (type === 'polygon') {
        reactECref.current?._toolbars?.draw?._toolbarContainer?.children[1]?.click()
      } else if (type === 'polyline') {
        reactECref.current?._toolbars?.draw?._toolbarContainer?.children[0]?.click()
      }
    }
  }

  const openPopup = marker => {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup()
      })
    } else if (marker && marker.latlng) {
      marker.target.openPopup()
    }
  }

  const onAdded = (e, b) => {
    setState((prevState) => ({
      ...prevState,
      fitBounds: { ...prevState.fitBounds, [b]: e.target.getBounds() }
    }))
    setIsCallBoundsFit(true)
  }

  const setBoundOptions = () => {
    return {}
  }

  const viewData = (row, e) => {
    let points = e.target._latlngs.map(latlng => [latlng.lng, latlng.lat])
    var line = turf.lineString(points)
    var pt = turf.point([e.latlng.lng, e.latlng.lat])
    var nearestPoint = turf.nearestPointOnLine(line, pt, { units: 'meters' })

    var nlatlng = props.routes[row.startPositionId]['positions'][
      nearestPoint['properties']['index']
    ]

    let timeFormat = getDateTimeFormat()

    let t = moment(nlatlng.fixTime)
      .tz(props.serverTimeZoneName)
      .format(timeFormat)

    let html =
      '<div class="position-box"><strong>' +
      row.deviceName +
      '</strong><div class="position-box-body">Address: ' +
      (ReactHtmlParser(nlatlng.address)[0] || '') +
      ' <br />Time: ' +
      t +
      '<br/> Speed : ' +
      // (nlatlng.speed * 1.852).toFixed(0) 
      setAttributeFormat('speed', nlatlng.speed) +
      '<br />Coordinates: ' +
      e.latlng.lat.toFixed(8) +
      ', ' +
      e.latlng.lng.toFixed(8) +
      '</div></div>'

    e.target.bindPopup(html).openPopup(e.latlng)

    if (e.sourceTarget._map) {
      //L.marker([nearestPoint.geometry.coordinates[1], nearestPoint.geometry.coordinates[0]]).addTo(e.sourceTarget._map);
      // [lat, lng]
      //L.marker([nlatlng.latitude, nlatlng.longitude]).addTo(e.sourceTarget._map);
    }
  }

  const clearSelection = () => {
    if (mapRef) {
      mapRef.current.removeLayer(state.kmlLayer)
    }
  }

  const updateVisible = () => {
    setTimeout(() => {
      let bounds = []
      geoFence?.map(g => {
        if (g.visible === true && state.fitBounds[g.id]) {
          bounds.push(state.fitBounds[g.id])
        }
        return null
      })
      if (bounds.length) {
        mapRef.current?.fitBounds(bounds)
      }
    }, 50)
  }

  let polylines = []
  let waypoints = []

  let timeFormat = getDateTimeFormat()

if (
  props.routes !== null &&
  props.routes !== undefined &&
  Object.keys(props.routes).length > 0 &&
  mapRef.current
) {
    Object.keys(props.routes).map(key => {
      if (props.routes[key].visible) {
        let p = []
        let obj = props.routes[key]

        if (props.routes[key]) {
          polylines.push(
            <Marker
              key={obj.row.startPositionId + 'start'}
              position={[obj.row.startLat, obj.row.startLon]}
              icon={L.icon({
                iconUrl: '/assets/images/maps/start-marker.svg',
                iconSize: [40, 40],
                iconAnchor: [10, 40],
                popupAnchor: [0, -40]
              })}
            >
              <Popup>
                <div className='position-box'>
                  <strong style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{obj.row.deviceName}
                    {props.monitoring &&
                      (<button style={{ background: "transparent", border: "none", color: "white" }} onClick={props.hideTracks}>
                        X
                      </button>)
                    }
                  </strong>
                  <div className='position-box-body'>
                    Address: {ReactHtmlParser(obj.row.startAddress)} <br />
                    Time:{' '}
                    {moment(obj.row.startTime)
                      .tz(props.serverTimeZoneName)
                      .format(timeFormat)}
                    <br />
                    Coordinates: {obj.row.startLat + ', ' + obj.row.startLon}
                  </div>{' '}
                </div>
              </Popup>
            </Marker>
          )
          polylines.push(
            <Marker
              key={obj.row.endPositionId + 'end'}
              position={[obj.row.endLat, obj.row.endLon]}
              icon={L.icon({
                iconUrl: '/assets/images/maps/end-marker.svg',
                iconSize: [40, 40],
                iconAnchor: [10, 40],
                popupAnchor: [0, -40]
              })}
            >
              <Popup>
                <div className='position-box'>
                  <strong style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{obj.row.deviceName}
                    {props.monitoring &&
                      (<button style={{ background: "transparent", border: "none", color: "white" }} onClick={props.hideTracks}>
                        X
                      </button>)
                    }
                  </strong>
                  <div className='position-box-body'>
                    Address: {ReactHtmlParser(obj.row.endAddress)} <br />
                    Time:{' '}
                    {moment(obj.row.endTime)
                      .tz(props.serverTimeZoneName)
                      .format(timeFormat)}
                    <br />
                    Coordinates: {obj.row.endLat + ', ' + obj.row.endLon}
                  </div>{' '}
                </div>
              </Popup>
            </Marker>
          )

          obj.positions.map(pos => {
            p.push(L.latLng(pos.latitude, pos.longitude))
            waypoints.push({ latLng: L.latLng(pos.latitude, pos.longitude) })
            return null
          })

          polylines.push(
            <Polyline
              pane='overlayPane'
              // onClick={e => {props.monitoring ? null : viewData(obj.row, e)}}
              // onClick={props.monitoring ? null : e => viewData(obj.row, e)}
              // onAdd={e => onAdded(e, key)}
              eventHandlers={{
                add: (e) => {
                  onAdded(e, key);
                },
                click: props.monitoring ? undefined : (e) => viewData(obj.row, e),
              }}
              key={`${key}-${obj.row.color}`}
              color={obj.row.color}
              positions={p}
              weight={6}
            >
              {/* {props.monitoring && 
                  <Popup >
                    <button style={{ border:"none",  color:"white"}} onClick={props.hideTracks}>
                      Hide Tracks
                    </button>
                    </Popup>
                  }  */}
              <TextPath
                positions={p}
                text='&#x25B6;          '
                repeat
                offset={8}
                attributes={{ 'font-size': 30, fill: obj.row.color }}
              />
            </Polyline>
          )
        }
      }
      return null
    })
  }

  let position = state.position
  useEffect(() => {
    let bounds = []
    let zoom = state.zoom
    if (Object.keys(state.fitBounds).length) {
      Object.keys(state.fitBounds).map(id => {
        if (Object.keys(props.routes).length) {
          let obj = props.routes[id]
          if (obj && obj.visible) {
            if (state.fitBounds[id] && Object.keys(state.fitBounds[id]).length > 0) {
              bounds.push(state.fitBounds[id])
            }
          }
        }
        return null
      })
    } else {
      if (logInUser?.zoom) {
        position = [
          logInUser?.latitude || 0,
          logInUser?.longitude || 0
        ]
        zoom = logInUser?.zoom
      } else if (ServerSetting?.zoom) {
        position = [
          ServerSetting?.latitude || 0,
          ServerSetting?.longitude || 0
        ]
        zoom = ServerSetting?.zoom
      }
    }
    setState((prevState) => ({
      ...prevState,
      zoom
    }))
    if (bounds.length) {
      mapRef.current?.fitBounds(bounds);
    }
    setnewBounds(bounds)
    if (isCallBoundsFit) {
      setIsCallBoundsFit(false)
    }
  }, [props.routes, isCallBoundsFit])

  useEffect(() => {
    geoFence?.forEach(obj => {
      if (obj.attributes.type === 'kmlFile' && obj.visible === true) {
        const coordinates = obj?.attributes?.latlng;
        if (coordinates) {
          if (coordinates.length) {
            mapRef.current?.fitBounds(coordinates);
          }
          setnewBounds(coordinates);
        }
      }
    });
  }, [geoFence, newBounds]);


  const geofences = geoFence?.map(obj => {
    if (obj.attributes.type === 'circle' && obj.visible === true) {
      return (
        <Circle
          // onAdd={e => onAdded(e, obj.id)}
          eventHandlers={{
            add: (e) => {
              onAdded(e, obj.id)
            },
          }}
          key={obj?.id}
          id={obj.id}
          radius={obj.attributes.radius}
          center={obj.attributes.latlng}
          color={obj.attributes.color}
        >
          <Tooltip direction={'top'} permanent>
            <div>
              <span>{ReactHtmlParser(obj.name)}</span>
            </div>
          </Tooltip>
        </Circle>
      )
    } else if (obj.attributes.type === 'polygon' && obj.visible === true) {
      return (
        <Polygon
          // onAdd={e => onAdded(e, obj.id)}
          eventHandlers={{
            add: (e) => {
              onAdded(e, obj.id)
            },
          }}
          id={obj.id}
          key={obj.id + '__1'}
          positions={obj.attributes.latlng}
          color={obj.attributes.color}
        >
          <Tooltip direction={'top'} permanent>
            <div>
              <span>{ReactHtmlParser(obj.name)}</span>
            </div>
          </Tooltip>
        </Polygon>
      )
    } else if (obj.attributes.type === 'polyline' && obj.visible === true) {
      return (
        <Polyline
          // onAdd={e => onAdded(e, obj.id)}
          eventHandlers={{
            add: (e) => {
              onAdded(e, obj.id)
            },
          }}
          id={obj.id}
          key={obj.id + '__1'}
          positions={obj.attributes.latlng}
          color={obj.attributes.color}
        >
          <Tooltip direction={'top'} permanent>
            <div>
              <span>{ReactHtmlParser(obj.name)}</span>
            </div>
          </Tooltip>
        </Polyline>
      )
    } else if (
      obj &&
      obj.attributes.type === "kmlFile" &&
      obj.visible === true
    ) {
      const coordinates = obj?.attributes?.latlng;

      return (
        <FeatureGroup
          // onAdd={e => onFeatureGroupAdd(e, obj.id)}
          // ref={_onFeatureGroupReady}
          key={obj.id}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* Render Polyline with the extracted coordinates */}
          <Polyline positions={coordinates} color={"red"} />
        </FeatureGroup>
      )
    }

    return ''
  })

  let crs = {}
  if (['yandexMap', 'yandexSat'].includes(mapLayer?.id)) {
    crs = { crs: L.CRS.EPSG3395 }
  }

  const drawOptions = {
    shapeOptions: {
      color: props.themecolors.themeDarkColor,
      opacity: 1,
      newShap: true
    }
  }
  const drawSettings = {
    ...settings,
    circle: drawOptions,
    polygon: drawOptions,
    polyline: drawOptions
  }
  const thisMap = [
    <MapContainer
      boundsOptions={setBoundOptions}
      bounds={newBounds && newBounds.length ? newBounds : null}

      // bounds={props.listType === 'tracks' ?  kmlBounds : props.bounds.length ? props.bounds : null}

      key={1}
      center={position}
      zoom={state.zoom}
      zoomControl={false}
      ref={mapRef}
      style={{ height: '100%' }}
      {...crs}
    >
      <TileLayer
        {...mapLayer}
        maxNativeZoom={mapLayer?.maxZoom}
        maxZoom={mapLayer?.maxZoom}
        minZoom={state.minZoom}
      />
      <ZoomControl position={'topright'} />
      {props.monitoring && (
        <button
          style={{
            position: 'absolute',
            bottom: '35px',
            left: '56%',
            transform: 'translateX(-50%)',
            zIndex: '10000',
            background: props.themecolors?.backgroundColor,
            border: 'none',
            padding: '10px',
            color:props.themecolors?.textColor,
            borderRadius: "6px",
            cursor: 'pointer' // Set cursor to pointer
          }}
          onClick={props.hideTracks}
        // onMouseDown={(e) => e.stopPropagation()} // Prevent map click
        >
          {props.translate('Close Tracks')}
        </button>

      )}

      {state.MarkerDOM}
      {polylines}
      {geofences}
      {state.polylineMarkers}
      <div className='map-filters-wrapper' style={{ marginRight: "5rem" }}>
        <MapFilters
          disableBottomLeftFilters
          disablePOIFilters
          showMeasurementTracks
          updateVisible={updateVisible}
          themecolors={props.themecolors}
          translate={props.translate}
          mapRef={mapRef.current}
          showListType={props.showListType}
          listType={props.listType}
          {...props}
          {...state}


        />
        <div style={{ display: 'none' }}>
          <button
            style={{ position: 'absolute', left: '-10000px' }}
            onClick={() => addMeasureShape('polygon')}
            id='addMeasurePolygon'
          ></button>
          <button
            style={{ position: 'absolute', left: '-10000px' }}
            onClick={() => addMeasureShape('polyline')}
            id='addMeasurePolyline'
          ></button>
        </div>
        <FeatureGroup key={2}>
          <EditControl
            onMounted={_onMeasureControlReady}
            className='control'
            draw={drawSettings}
            position='bottomleft'
            onDrawStop={_onMeasureDrawStop}
            onDrawStart={_onMeasureDrawStart}
            onDrawVertex={_onMeasureDrawCreated}
          />
        </FeatureGroup>
      </div>
    </MapContainer>
  ]

  useEffect(() => {
    if (logInUser?.zoom) {
      setState((prevState) => ({
        ...prevState,
        zoom: logInUser.zoom,
        lat: logInUser.latitude,
        lng: logInUser.longitude
      }))
    } else if (ServerSetting?.zoom) {
      setState((prevState) => ({
        ...prevState,
        zoom: ServerSetting.zoom,
        lat: ServerSetting.latitude,
        lng: ServerSetting.longitude
      }))
    }

  }, [logInUser])

  useEffect(() => {
    // Logic for componentWillReceiveProps
    if (props.kmlContent) {
      if (!state.kmlLayer && mapRef) {
        try {
          const layer = new L.KML(props.kmlContent);
          setState((prevState) => ({
            ...prevState,
            kmlLayer: layer
          }))
          if (mapRef.current) {
            mapRef.current.addLayer(layer);
            mapRef.current.fitBounds(layer.getBounds());
          }
        } catch (error) {
          console.error("Error creating KML layer=======", error);
        }
      } else {
        clearSelection();
        setState((prevState) => ({
          ...prevState,
          kmlLayer: null
        }))
        const layer = new L.KML(props.kmlContent);
        setState((prevState) => ({
          ...prevState,
          kmlLayer: layer
        }))
        if (mapRef.current) {
          mapRef.current.addLayer(layer);
          const layersBounds = layer?.getBounds()
          if (layersBounds?.length) {
            mapRef.current.fitBounds(layersBounds);
          }
        }
      }
    }
  }, [props.kmlContent]);
  return (
    <React.Fragment>
      <button
        id='clearSelection'
        onClick={clearSelection}
        style={{ display: 'none' }}
      >
        clear
      </button>
      {['osm', ''].includes(mapLayer?.id) ? thisMap : null}
      {['carto'].includes(mapLayer?.id) ? thisMap : null}
      {['gccStreet'].includes(mapLayer?.id) ? thisMap : null}
      {['googleTerrain'].includes(mapLayer?.id) ? thisMap : null}
      {['googleSatellite'].includes(mapLayer?.id) ? thisMap : null}
      {['googleHybrid'].includes(mapLayer?.id) ? thisMap : null}
      {['googleRoad'].includes(mapLayer?.id) ? thisMap : null}
      {['baidu'].includes(mapLayer?.id) ? thisMap : null}
      {['yandexMap', 'yandexSat'].includes(mapLayer?.id)
        ? thisMap
        : null}
    </React.Fragment>
  )
}


export default RouteMap
