import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import {
  checkPrivileges,
  errorHandler,
} from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import Button from '../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import Grid from '@mui/material/Grid'
import EmptyState from '../../Components/common/EmptyState'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import instance from '../../axios'
import CommonTableSection from '../Users/Components/commonTableSection'
import { addTypes, getTypes } from '../../Actions/Services'

const Services = (props) => {

  const dispatch = useDispatch();

  const logInUser = useSelector(states => states.logInUsers)
  const themecolors = useSelector(states => states.themeColors)
  const services = useSelector(states => states.services)

  const [state, setState] = useState({
    serviceForm: {},
    typeSelection: '',
    expenseId: '',
    mode: '',
    isVisableSubmitBtn: false,
    initFetch: false,
    currentPage: 1,
    pagesize: 50,
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 0,
      currentDevice: props.deviceId,
      hasNext: true,
      searchText: ''
    },
    deleteForm: {},
    servicesdata: [],
    expense: [],
    rowsPerPage: 5
  })

  useEffect(() => {
    if (logInUser && logInUser.id && !state.initFetch) {
      setState(prevState => ({
        ...prevState,
        initFetch: true
      }));

      fetchData(logInUser, state.currentPage, state.pagesize);
    }
  }, [logInUser, state.initFetch, state.currentPage, state.pagesize, fetchData]);

  useEffect(() => {
    fetchServices(state.searchText, state.servicesdata)
  },
    [state.searchText, state.servicesdata]
  )

  // useEffect(() => {
  //   return () => {
  //     setState({
  //       serviceForm: {},
  //       mode: '',
  //       initFetch: false,
  //       currentPage: 1,
  //       pageSize: 50,
  //       itemPagination: {
  //         items: [],
  //         total: 0,
  //         currentPage: 0,
  //         currentDevice: props.deviceId,
  //         hasNext: true,
  //         searchText: ''
  //       }
  //     });
  //   };
  // }, [props.deviceId]);

  const searchItems = (text) => {
    setState(prevState =>
    ({
      ...prevState,
      searchText: text
    })
    )
  }

  const fetchServices = (text, services) => {
    if (!text) {
      dispatch(getTypes(services));
      return;
    }
    const lowerCaseText = text.toLowerCase(); 
    const filteredServices = services.filter(u =>
      (u.id + u.name).toLowerCase().includes(lowerCaseText) 
    );
  
    dispatch(getTypes(filteredServices));
  }

  const fetchData = (logInUser, page, perPage, pagination) => {
    let items = state.itemPagination.items
    instance({
      method: 'GET',
      url:
        logInUser && logInUser.id
          ? `/api/expensetypes/get?userId=${logInUser.id}&all=${true}`
          : `/api/expensetypes`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        page: page,
        limit: perPage,
      }
    })
      .then(response => {
        setState(prevState =>
        ({
          ...prevState,
          itemPagination: {
            ...response,
            items: items.concat(response.data)
          },
          currentPage: response.hasNext ? response.page + 1 : response.page,
          servicesdata: pagination ? items.concat(response.data) : (response.data)
        }))
        dispatch(getTypes(pagination ? items.concat(response.data) : response.data))
      })
      .catch(error => {
        errorHandler(error, dispatch)
      })
  }

  const openCreateTypeFrom = () => {
    // setState((prevState) => ({
    //   ...prevState,
    //   mode: '',
    //   serviceForm: {},
    //   typeSelection: ''
    // }));

    setState((prevState) => ({
      ...prevState,
      mode: 'create',
      serviceForm: {},
      typeSelection: 'createType',isVisableSubmitBtn:false
    }));
  };

  const openCreateSubTypeFrom = () => {
    // setState((prevState) => ({
    //   ...prevState,
    //   mode: '',
    //   serviceForm: {},
    //   typeSelection: ''
    // }));

    setState((prevState) => ({
      ...prevState,
      mode: 'create',
      serviceForm: {},
      typeSelection: 'createSub',
      isVisableSubmitBtn:false
    }));
  }

  const openEditSubTypeFrom = (data) => {
    // setState((prevState) => ({
    //   ...prevState,
    //   mode: '',
    //   serviceForm: {},
    //   typeSelection: ''
    // }));

    setState((prevState) => ({
      ...prevState,
      mode: 'editSub',
      serviceForm: data[0],
      typeSelection: 'updateSub',
      isVisableSubmitBtn:false
    }));
  }

  const openEditTypeFrom = (data) => {
    // setState((prevState) => ({
    //   ...prevState,
    //   mode: '',
    //   serviceForm: {},
    //   typeSelection: ''
    // }));

    setState((prevState) => ({
      ...prevState,
      mode: 'editSub',
      serviceForm: data,
      typeSelection: 'updateType',
      isVisableSubmitBtn:false
    }));
  }

  const onDeleteSub = (item) => {
    setState((prevState) => ({
      ...prevState,
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    }));
  }

  const removeSubType = () => {
    const id = state.deleteForm.id
    instance({
      url: `/api/expensetypes/subtype/${id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
        dispatch(
          Notifications.success({
            message: props.translate('subTypeIsDeleted'),
            autoDismiss: 10
          })
        )
        setState((prevState) => ({
          ...prevState,
          deleteForm: {},
          onDeleteConfirmation: false
        }));
        getExpense(state.expenseId)
      }).catch(error => {
        errorHandler(error,dispatch)
      })
  }

  const showSubTypes = (service) => {
    getExpense(service.id)

    setState((prevState) => ({
      ...prevState,
      mode: '',
      serviceForm: {},
    }));

    setState((prevState) => ({
      ...prevState,
      mode: 'Show',
      serviceForm: service,
      expenseId: service.id
    }));
  }

  const closeCreateFrom = () => {
    setState(prevState =>
    ({
      ...prevState,
      mode: '',
      serviceForm: '',
      typeSelection: '',
      isVisableSubmitBtn:false
    }))
  }

 const closeEditForm = () => {
    setState(prevState =>
    ({
      ...prevState,
      mode: '',
      serviceForm: '',
      typeSelection: '',
      isVisableSubmitBtn:false
    }))
  }

 const handleChange = (name, value) => {
    setState(prevState =>
    ({
      ...prevState,
      serviceForm: {
        ...prevState.serviceForm,
        [name]: value
      }
    })
    )
  }

  const checkRequiredFields = () => {
    let { name, label,subtypenum } = state.serviceForm
    let isValid=state.typeSelection==="createSub"||state.typeSelection==="updateSub"?subtypenum:true
    if ((name || label)&&isValid) {
      setState(prevState => ({
        ...prevState,
        isVisableSubmitBtn: true
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        isVisableSubmitBtn: false
      }))
    }
  }
useEffect(()=>{
  checkRequiredFields()
},[state.serviceForm])
  const getExpense = (id)=> {
    if (id)
 {
      instance({
        method: 'GET',
        url: `/api/expensetypes/subtype?expenseTypeId=${id}&search=`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          setState(prevState => ({
            ...prevState,
            expense: res
          }))
        })
        .catch(error => {
          errorHandler(error, dispatch)
        })
    }
  }

 const submitForm = () => {
    let { mode, typeSelection, serviceForm, expenseId } =state
    let obj
    let params = ''
    let api
    if (mode === 'create') {
      if (typeSelection === 'createType') {
        obj = {
          ...serviceForm,
          attributes: {},
          parentId: 0
        }
        api = `/api/expensetypes/${params}`
      } else if (typeSelection === 'createSub') {
        obj = {
          ...serviceForm,
          parentId: 0,
          expensetypeId: expenseId,
        }
        api = `/api/expensetypes/subtype/${params}`
      }
    }
    if (mode === 'editSub') {
      if (typeSelection === 'updateType') {
        obj = { ...serviceForm }
        params = serviceForm.id
        api = `/api/expensetypes/${params}`
      } else if (typeSelection === 'updateSub') {
        obj = { ...serviceForm }
        params = serviceForm.id
        api = `/api/expensetypes/subtype/${params}`
      }
    }
    setState((prevState)=>({
      ...prevState,
      isVisableSubmitBtn:false
    }))
    if (obj) {
      instance({
        url: api,
        method: `${mode === 'editSub' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(res => {
          if (mode === 'editSub') {
            if (typeSelection === 'updateType') {
             dispatch(addTypes(res))
             dispatch(
                Notifications.success({
                  message:props.translate('savedTypeIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState(prevState=>({
                ...prevState,
                isVisableSubmitBtn:false
              }))
            } else {
              dispatch(
                Notifications.success({
                  message: props.translate('savedSubTypeIsUpdated'),
                  autoDismiss: 10
                })
              )
            }
          } else if (mode === 'create') {
            if (typeSelection === 'createType') {
              dispatch(addTypes(res))
              dispatch(
                Notifications.success({
                  message: props.translate('typeIsCreated'),
                  autoDismiss: 10
                })
              )
      
            } else {
              dispatch(
                Notifications.success({
                  message: props.translate('SubTypeIsCreated'),
                  autoDismiss: 10
                })
              )
            }
          }
          closeCreateFrom()
        })
        .catch(error => {
          errorHandler(error,dispatch)
        })
    }
  }

  //  const onRemovedItem = (item) => {
  //     setState(prevState=>({
  //       ...prevState,
  //       deleteForm: { ...item[0] },
  //       onDeleteConfirmation: true
  //     }))
  //   }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setState(prevState => ({
      ...prevState,
      rowsPerPage
    }));
  };

 const onCancel = () => {
    setState(prevState=>({
      ...prevState,
      onDeleteConfirmation: false,
      isVisableSubmitBtn:false
    }))
  }

  const fetchMoreItems = () => {
    const { currentPage, pagesize } = state;
    const pagination = true;

    fetchData(logInUser, currentPage, pagesize, pagination);
  };

  const endMessage =
    state.itemPagination && state.itemPagination.total > 0 ? (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        -- {props.translate('end')} --
      </p>
    ) : (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        -- {props.translate('end')} --
      </p>
    )

  if (checkPrivileges('service')) {
    const pp = {
      ...props,
      services: services
    }

    return (
      <div>
        <Layout
          {...pp}
          endMessage={endMessage}
          openCreateFrom={openCreateTypeFrom}
          classFromChildren='has-padding'
          editCommandForm={showSubTypes}
          searchItems={searchItems}
          onEditType={openEditTypeFrom}
          fetchMoreItems={fetchMoreItems}
          {...state}
        >
          <div className='main-content-page'>
            {state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={onCancel}
                onOk={removeSubType}
                title={props.translate('areYouWantToDelete')}
                children={state.deleteForm?.name || state.deleteForm?.label || ''}
              />
            )}
            <div
              style={{
                background: themecolors.backgroundColor,
                color: themecolors.textColor,
                borderRadius: 6,
                padding: 16
              }}
            >
              {state.mode === 'Show' ? (
                <CommonTableSection
                  onEdit={openEditSubTypeFrom}
                  themecolors={themecolors}
                  componentData={state?.expense || []}
                  showCheckbox={false}
                  showGroupAccess={false}
                  onDelete={onDeleteSub}
                  label={props.translate('services')}
                  canUpdate={true}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPage={state?.rowsPerPage}
                  createButton={
                    <div style={{ marginLeft: 10 }}>
                      <Button onClick={openCreateSubTypeFrom}>
                        {props.translate('Create')}
                      </Button>
                    </div>
                  }
                  parent={2}
                  rowDefinition={[
                    {
                      id: 'label',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('sharedName')
                    },
                    {
                      id: 'subtypeGroup',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('subtypeGroup')
                    },
                    {
                      id: 'subtypenum',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('subtypenum')
                    },
                    {
                      id: 'unitPrice',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('unitPrice')
                    }
                  ]}
                  canDelete={true}
                  canRemove={false}
                  canAssign={false}
                />
              ) : null}
              {state.mode === 'editSub' ||
                state.mode === 'create' ? (
                <EditSubForm
                  form={state.serviceForm}
                  handleChange={handleChange}
                  translate={props.translate}
                  submitForm={submitForm}
                  closeEditForm={closeEditForm}
                  mode={state?.mode}
                  isVisableSubmitBtn={state?.isVisableSubmitBtn}
                  typeSelection={state?.typeSelection}
                  field={
                    state.typeSelection === 'createSub' ||
                      state.typeSelection === 'updateSub'
                      ? 'label'
                      : 'name'
                  }
                />
              ) : null}
              {state.mode === '' ? (
                <EmptyState
                  text={props.translate('noServicesSelected')}
                />
              ) : null}
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

const EditSubForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  typeSelection,
  field
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('services')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='name'
            required
            margin='dense'
            label={translate('sharedName')}
            variant='outlined'
            fullWidth
            value={form.name||form.label||""}
            // InputLabelProps={{
            //   shrink: true
            // }}
            onChange={e => handleChange(field, e.target.value)}
          />
        </Grid>
        {(typeSelection === 'createSub' || typeSelection === 'updateSub') && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='subtypeGroup'
                margin='dense'
                label={translate('subtypeGroup')}
                variant='outlined'
                fullWidth
                value={form.subtypeGroup}
                onChange={e => handleChange('subtypeGroup', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='subtypenum'
                margin='dense'
                required
                label={translate('subtypenum')}
                variant='outlined'
                fullWidth
                value={form.subtypenum}
                onChange={e => handleChange('subtypenum', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='unitPrice'
                margin='dense'
                label={translate('Price')}
                variant='outlined'
                fullWidth
                type='number'
                value={form.unitPrice}
                onChange={e => handleChange('unitPrice', e.target.value)}
              />
            </Grid>
          </>
        )}
        {(typeSelection === 'createType' || typeSelection === 'updateType') && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='description'
              margin='dense'
              label={translate('sharedDescription')}
              variant='outlined'
              fullWidth
              value={form.description||""}
              onChange={e => handleChange('description', e.target.value)}
            />
          </Grid>
        )}
      </Grid>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <div style={{ marginLeft: 'auto' }} />
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeEditForm}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {checkPrivileges('commandCreate') && (
          <Button
            size='small'
            onClick={submitForm}
            disabled={!isVisableSubmitBtn}
          >
            {translate(mode === 'editSub' ? 'update' : 'sharedCreate')}
          </Button>
        )}
      </div>
    </div>
  )
}


export default ((withLocalize(Services)))