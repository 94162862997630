import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { connect } from 'react-redux'
import UncheckIcon from './../../assets/nav/uncheck-box.svg';
import CheckIcon from './../../assets/nav/check-box.svg';

const CheckboxLabels = (props) => {

  const [checked, setChecked] = useState(props.checked);
  const [indeterminate, setIndeterminate] = useState(props.indeterminate);

  const handleChange = (name) => (event) => {
    setChecked(event.target.checked)
    props.onChange(event, name);
  };

  useEffect(()=>{
    setChecked(props.checked)
    setIndeterminate(props.indeterminate)
  },[props.checked,props.indeterminate])

    return (
      <>
        <style>
          {`
            .theme-input-checkbox {
              color: inherit;
              padding: 2px;
            }

            .theme-input-custom-label:not(:empty) {
              margin-left: 15px;
              font-size: 0.875rem;
            }

            .custom-root {
              color: inherit;
              margin: 0;
              padding: 0;
            }

            .custom-label {
              color: inherit;
            }
          `}
        </style>
        <FormControlLabel
          control={
            <Checkbox
              disableRipple
              icon={
                <svg stroke="currentColor" width={18} height={18}>
                  <use xlinkHref={`${UncheckIcon}#icon`} />
                </svg>
              }
              checkedIcon={
                <svg stroke="currentColor" width={18} height={18}>
                  <use xlinkHref={`${CheckIcon}#icon`} />
                </svg>
              }
              checked={checked ? true : false}
              indeterminate={indeterminate ? true : false}
              onChange={handleChange(
                props.value || props.id
              )}
              classes={{
                checked: 'theme-input-checkbox',
                root: 'custom-root',
              }}
              color="default"
            />
          }
          classes={{
            label: 'custom-label theme-input-custom-label',
          }}
          label={props?.label || ''}
          sx={{
            color: props.themecolors?.textColor || 'inherit',  // Explicitly set label color
            '& .MuiFormControlLabel-label': {
              color: props.themecolors?.textColor || 'inherit',  // Apply to label specifically
            },
          }}
        />
      </>
    );
  }

const mapState = state => ({
  themes: state.themes
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(CheckboxLabels);